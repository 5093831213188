import React, { ReactElement } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { NotFound } from '../loadable-elements/NotFound';
import ConsumerTestGuide from '../pages/ConsumerTestGuide/ConsumerTestGuide';
import TestedProductPage from '../pages/ConsumerTestGuide/TestedProductPage/TestedProductPage';

const TestedProductRoute = (): ReactElement => {
  const match = useRouteMatch<{ testSlug: string; guideSlug: string }>();
  const { testSlug, guideSlug } = match.params;

  if (!testSlug) {
    return <NotFound />;
  }

  return <TestedProductPage testSlug={testSlug} guideSlug={guideSlug} />;
};

export default TestedProductRoute;

export const ConsumerTestRouter = (): ReactElement => (
  <Switch>
    {/* Render a specific consumer test guide */}
    <Route exact path="/forbrukertester/:slug">
      <ConsumerTestGuide />
    </Route>

    {/* Route for TestedProductPage */}
    <Route exact path="/forbrukertester/:guideSlug/:testSlug">
      <TestedProductRoute />
    </Route>

    {/* Fallback for invalid paths */}
    <Route>
      <NotFound />
    </Route>
  </Switch>
);
