import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Text } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { useIntersectionObserver } from 'usehooks-ts';
import { ColumnBlockHeader, ColumnContainer } from '../../../../components/SectionBlocks/ColumnBlock';
import { ArticleCard } from '../../../../components/SectionBlocks/ColumnContent/VerticalArticleBlock';

export const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  border-top: 1px solid ${({ theme }) => theme.border.subtle};

  @media (max-width: ${breakpoints.l}) {
    flex-direction: column;
    border-top: 0;
  }
`;

export const Section = styled.section<{ $isTopElement?: boolean; $isBottomElement?: boolean }>`
  padding: ${({ $isBottomElement }) => ($isBottomElement ? 0 : spacing.space64)} 0;
  width: 100%;
  scroll-margin-top: ${({ $isTopElement }) => ($isTopElement ? '200px' : '20px')};
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.border.subtle};

  @media (max-width: ${breakpoints.l}) {
    padding: ${({ $isBottomElement }) =>
      $isBottomElement ? `0 ${spacing.space24}` : `${spacing.space32} ${spacing.space24}`};
  }

  &:nth-of-type(2) {
    border-bottom: none;
    padding-bottom: 0;
  }

  &:nth-of-type(3) {
    padding-top: 0;
  }
`;

export const Main = styled.div`
  max-width: 1520px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid ${({ theme }) => theme.border.subtle};

  @media (max-width: ${breakpoints.l}) {
    margin-top: 62px;
    border-left: 0;
  }

  ${Section}:last-child {
    border-bottom: 0 !important;
    padding-bottom: ${spacing.space120};

    @media (max-width: ${breakpoints.l}) {
      padding-bottom: ${spacing.space48};
    }
  }
`;

export const SectionContent = styled.div<{ $unset?: boolean; $relative?: boolean }>`
  position: ${({ $relative }) => ($relative ? 'relative' : 'static')};
  max-width: 736px;
  flex-grow: 1;
  flex-basis: 100%;

  @media (max-width: ${breakpoints.l}) {
    ${({ $unset }) =>
      $unset &&
      css`
        max-width: $unset;
      `}
    width: calc(100vw - 48px);
  }

  ${ColumnContainer} {
    ${ColumnBlockHeader} {
      margin-bottom: 46px;
      @media (max-width: ${breakpoints.m}) {
        margin-bottom: 38px;
      }
    }
    ${ArticleCard} {
      /* LinkCards have an outline and these don't, so they don't vertically align */
      margin-top: 2px;
      border-radius: 3px;
      overflow: hidden;
    }
  }
`;

export const SectionHeader = styled(Text)`
  margin: 0;
  margin-bottom: ${spacing.space48};

  @media (max-width: ${breakpoints.l}) {
    margin-bottom: ${spacing.space40};
  }
`;

export const Aside = styled.aside`
  max-width: 400px;
  min-width: 240px;
  width: 25vw;

  @media (max-width: ${breakpoints.l}) {
    position: absolute;
    min-width: unset;
    border-right: 0;
    min-height: unset;
    margin-left: -24px;
    max-width: unset;
    width: 100vw;
    z-index: 2;
  }
`;

export const FeedbackMargin = styled.div`
  margin-top: ${spacing.space32};

  @media (max-width: ${breakpoints.l}) {
    ${spacing.space16};
  }
`;

export const RelatedContentWrapper = styled.div`
  li:last-child .card {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

type ScrollComponentProps = {
  children?: React.ReactNode;
  fireOnInView: () => void;
  observerRef: React.RefObject<HTMLDivElement>;
  index: number;
};

export const ScrollComponent: React.FC<ScrollComponentProps> = ({ children, fireOnInView, observerRef, index }) => {
  const dataRef = useIntersectionObserver(observerRef, {});

  useEffect(() => {
    if (dataRef?.isIntersecting) {
      fireOnInView();
    }
  }, [dataRef, fireOnInView]);

  return (
    <Section $isTopElement={index === 0} ref={observerRef}>
      <SectionContent>{children}</SectionContent>
    </Section>
  );
};

export const CampaignWrapper = styled.div`
  @media (max-width: ${breakpoints.m}) {
    margin-top: 160px;
    margin-bottom: ${spacing.space32};
  }
`;

export const CarouselWrapper = styled.div`
  margin-top: ${spacing.space64};
`;
