import React from 'react';
import styled from 'styled-components';
import { breakpoints, radius, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { Link } from 'react-router-dom';
import { Label } from '@naf/label';

export const Wrapper = styled.div`
  padding-top: ${spacing.space32};

  :first-of-type {
    padding-top: 0;
  }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
export const ListElement = styled((props) => <Link {...props} />)`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  padding: ${spacing.space32} 0;
  border-bottom: 1px solid lightgrey;

  :first-of-type {
    padding-top: 0;
  }

  &:hover {
    border-radius: 4px;
    color: ${({ theme }) => (theme && theme.textLink?.color ? theme.textLink?.color : `#1a1a1a`)} !important;

    img,
    svg {
      transform: scale(1.05);
    }

    h3 {
      &::after {
        width: 100%;
      }
    }
  }

  @media (max-width: ${breakpoints.s}) {
    flex-direction: column;
  }
`;

export const StyledLabel = styled(Label)`
  position: absolute;
  margin-top: -${spacing.space12};
  margin-left: ${spacing.space12};
`;

export const ElementImage = styled.figure`
  z-index: 0;
  overflow: hidden;
  position: relative;
  border-radius: ${radius.s};
  margin: 0 ${spacing.space32} 0 0;
  width: 220px;
  height: 166px;

  img {
    transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 106%;
    object-fit: cover;
  }

  @media (max-width: ${breakpoints.s}) {
    width: 100%;
    height: auto;
    margin: 0 0 ${spacing.space32} 0;
    aspect-ratio: 4 / 3;
  }
`;

export const ElementInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  h3 {
    width: auto;

    @media (min-width: ${breakpoints.s}) {
      width: max-content;
    }

    @media (max-width: ${breakpoints.s}) {
      font-size: 1.25rem;
    }

    position: relative;
    margin: 0;
    &::after {
      content: '';
      width: 0;
      height: 2px;
      transition: width 0.3s cubic-bezier(0.23, 1, 0.32, 1);
      background-color: #ffdb16;
      position: absolute;
      left: 0;
      bottom: -4px;
    }
  }
`;

export const PriceInfoWrapper = styled.div`
  margin-top: auto;
  margin-bottom: -6px;
`;

export const ElementPrice = styled.strong`
  margin-top: 1rem;
  font-weight: 500;
  font-size: 1.125rem;

  @media (max-width: ${breakpoints.s}) {
  }
`;

export const LabelList = styled.ul`
  padding-left: 0;
  list-style: none;
  margin: 0;
  font-size: 1rem;

  @media (max-width: ${breakpoints.s}) {
    margin-bottom: ${spacing.space16};
  }
`;

export const LabelListElement = styled.li`
  display: inline-block;
  color: ${nafColor && nafColor.neutral.neutral6 ? nafColor.neutral.neutral6 : `#4D4D4D`};
  padding-right: 8px;

  &:not(:last-child)::after {
    content: '\\2022';
    color: #c1c0c1;
    padding-left: 8px;
  }

  &:last-child ::after {
    content: '';
  }
`;
