import React from 'react';
import TestListItem from '../TestListItem/TestListItem';
import { ConsumerTestedProductsResponse } from '../../../../../types/consumerTestType';

interface Props {
  guideSlug: string;
  testedProducts: ConsumerTestedProductsResponse['items'];
  isUpdatingProducts: boolean;
}

const TestList: React.FC<Props> = ({ testedProducts = [], isUpdatingProducts, guideSlug }) => {
  if (isUpdatingProducts) {
    return <TestListItem guideSlug={guideSlug} isUpdatingProducts testedProducts={null} key="loading-placeholder" />;
  }

  // Sort the tested products: winners first, then by score (highest to lowest)
  const sortedProducts = [...testedProducts].sort((a, b) => {
    const isWinnerA = a.score?.isWinner ? 1 : 0;
    const isWinnerB = b.score?.isWinner ? 1 : 0;

    if (isWinnerA !== isWinnerB) {
      return isWinnerB - isWinnerA;
    }

    const scoreA = a.score?.scoreValue || 0;
    const scoreB = b.score?.scoreValue || 0;
    return scoreB - scoreA;
  });

  return (
    <>
      {sortedProducts.map((product) => (
        <TestListItem
          guideSlug={guideSlug}
          testedProducts={product}
          key={`tested-product-${product.title}`}
          isUpdatingProducts={false}
        />
      ))}
    </>
  );
};

export default TestList;
