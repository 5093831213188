import styled, { css } from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { breakpoints, spacing } from '@naf/theme';
import { GridCol, GridRow } from '@naf/grid';

export const Wrapper = styled.div`
  > p {
    font-size: 1.6rem;
    padding: 1em;
  }

  h1 {
    margin: 0 0 16px;
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  margin-top: ${spacing.space16};
  margin-bottom: ${spacing.space56};

  @media (max-width: ${breakpoints.m}) {
    flex-direction: column;
    margin-bottom: ${spacing.space40};
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 324px;
  height: 282px;
  overflow: hidden;
  margin-right: ${spacing.space24};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: ${breakpoints.m}) {
    width: 100%;
    height: auto;
    margin-bottom: ${spacing.space24};
  }
`;

export const TopWrapperTextContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IngressWrapper = styled.div`
  margin-bottom: ${spacing.space24};

  p {
    margin: 0;
    font-size: 1.25rem;
  }
`;

export const TestListWrapper = styled.div`
  width: 100%;

  @media (max-width: ${breakpoints.m}) {
    margin-top: 12px;
  }
`;

export const DisclaimerWrapper = styled.div`
  @media (min-width: ${breakpoints.l}) {
    width: calc(75% + 12px);
  }

  margin-top: 30px;
`;

export const LabelList = styled.ul`
  padding-left: 0;
  list-style: none;
  margin-top: 8px;
  font-size: 1rem;
`;

export const LabelListElement = styled.li`
  display: inline-block;
  color: ${nafColor && nafColor.neutral.neutral6 ? nafColor.neutral.neutral6 : `#4D4D4D`};
  padding-right: 8px;

  &:not(:last-child)::after {
    content: '\\2022';
    color: #c1c0c1;
    padding-left: 8px;
  }

  &:last-child ::after {
    content: '';
  }
`;

export const ImagePlaceholder = styled.div<{ width?: number; height?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 2px;
  margin: 0;
  ${({ width }) =>
    width
      ? css`
          width: ${width}px;
          height: calc(${width}px / (16 / 9));
        `
      : css`
          width: 100%;
        `};
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `};
  position: relative;
  background-color: ${nafColor.neutral.neutral1};
  color: ${nafColor.neutral.neutral5};

  svg {
    transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  }

  z-index: 0;
`;

export const PlaceholderTextElement = styled.span`
  color: ${nafColor.neutral.neutral5};
  transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  text-align: center;
`;

export const LargeGrid = styled(GridRow)`
  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

export const SmallGrid = styled(GridRow)`
  @media (width >= calc(${breakpoints.m} + 1px)) {
    display: none;
  }
`;

export const MobileTestFilterWrapper = styled(GridCol)`
  display: none;

  @media (max-width: ${breakpoints.m}) {
    display: block;
    margin-bottom: ${spacing.space24};
  }
`;

export const DesktopTestFilterWrapper = styled.div`
  @media (min-width: ${breakpoints.l}) {
    width: calc(75% + 12px);
  }

  margin-top: -24px;

  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

export const PaywallWrapper = styled.div`
  padding-top: ${spacing.space40};
`;

export const ButtonWrapper = styled.div``;
