import React from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { ChevronRight } from '@styled-icons/material';
import { breakpoints, spacing } from '@naf/theme';

interface CustomBreadcrumbProps {
  basePath: string;
}

const CustomBreadcrumb: React.FC<CustomBreadcrumbProps> = ({ basePath }) => {
  const { guideSlug } = useParams<{ guideSlug?: string }>();
  const formatSlug = (slug: string) => slug.charAt(0).toUpperCase() + slug.slice(1).replace(/-/g, ' ');

  return (
    <StyledBreadcrumb>
      <StyledLink to={basePath}>Forbrukertester</StyledLink>
      <ChevronRight size={16} />
      {guideSlug && <StyledLink to={`${basePath}/${guideSlug}`}>{formatSlug(guideSlug)}</StyledLink>}
    </StyledBreadcrumb>
  );
};

export default CustomBreadcrumb;

const StyledBreadcrumb = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing.space16} ${spacing.space24};

  * + * {
    margin-left: ${spacing.space8};
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.typography.textLink};
  font-size: 1rem;

  @media (max-width: ${breakpoints.s}) {
    font-size: 0.875rem;
  }
`;
