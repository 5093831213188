import React from 'react';
import styled from 'styled-components';
import { PaywallBox } from '../../../../components/paywall/PaywallBox';
import { PaywallOption } from '../../../../../../types/paywallOptionType';
import { PaywallLink } from '../../../../../../types/paywallLinkType';

type Props = {
  paywallOptions: PaywallOption;
  paywallLink?: PaywallLink;
  paywallLinkSecondary?: PaywallLink;
};

export const ConsumerTestPaywall = ({ paywallLink, paywallLinkSecondary, paywallOptions }: Props) => (
  <Wrapper>
    <PaywallBox paywallOptions={paywallOptions} paywallLink={paywallLink} paywallLinkSecondary={paywallLinkSecondary} />
    <TransparentGradient />
  </Wrapper>
);

const TransparentGradient = styled.div`
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  bottom: 100%;
  height: 300px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1) 100%);
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;
