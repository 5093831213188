import { Text } from '@naf/text';
import { animation, breakpoints, spacing } from '@naf/theme';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from '@naf/button';

export type Variant = 'previous' | 'next' | 'list-item';

export const StyledLink = styled(Link)<{ $current: boolean; $inSidebar?: boolean; variant: Variant }>`
    padding: ${spacing.space16} ${spacing.space16} ${spacing.space16} ${spacing.space24};
    text-decoration: none;

  ${({ variant }) =>
    variant === 'next' || variant === 'previous'
      ? css`
          width: 100%;
          flex: 1 1 0;
          position: relative;
        `
      : css`
          border-top: 1px solid ${({ theme }) => theme.border.subtle};
        `}


  ${({ $current }) =>
    $current
      ? css`
          background: ${({ theme }) => theme.componentColors.cta.disabled};
          :hover {
            cursor: default;
            color: ${({ theme }) => theme.typography.defaultText};
          }
        `
      : css`
          :hover {
            background: ${({ theme }) => theme.componentColors.cta.disabled};
            img {
              transform: scale(105%);
            }`}
  }

  @media (max-width: ${breakpoints.m}) {
    ${({ variant }) => {
      if (variant === 'previous' || variant === 'next') {
        return css`
          padding: ${spacing.space16} 0 ${spacing.space16} ${spacing.space16};
        `;
      }
      return null;
    }}
`;

export const ProductName = styled(Text)`
  margin: 0;
`;

export const Label = styled(Text)`
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1rem;
  margin: 0;
`;

export const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spacing.space8};
  align-items: center;
`;

export const ImageContainer = styled.div<{ $variant?: Variant }>`
  overflow: hidden;
  height: 37.5px;
  width: 50px;
  background: ${({ theme }) => theme.border.subtle};
  img {
    width: 50px;
    height: 37.5px;
    object-fit: cover;
    border-radius: 2px;
    transition: transform 0.6s ${animation.easeIn};
  }

  @media (max-width: ${breakpoints.l}) {
    display: ${({ $variant }) => ($variant === 'list-item' ? 'block' : 'none')};
  }
`;

// RelatedModels
export const Container = styled.div<{ $inSidebar?: boolean; $showAll?: boolean }>`
  display: ${({ $inSidebar }) => ($inSidebar ? 'flex' : 'none')};
  flex-direction: column;
  margin-top: ${({ $inSidebar }) => ($inSidebar ? spacing.space80 : spacing.space48)};
  transition: height 0.3s;

  @media (max-width: ${breakpoints.l}) {
    display: ${({ $inSidebar }) => (!$inSidebar ? 'flex' : 'none')};
    ${({ $inSidebar, $showAll }) =>
      !$inSidebar &&
      css`
        overflow-y: hidden;
        margin-left: -24px;
        width: 100vw;
        height: ${$showAll ? '752px' : '256px'};
      `};
  }
`;

export const ButtonContainer = styled.div<{ $inSidebar: boolean }>`
  position: absolute;
  bottom: -18px;
  flex-direction: column;
  z-index: 1;
  align-items: center;
  width: calc(100vw);
  margin-left: -24px;
  display: none;

  @media (max-width: ${breakpoints.l}) {
    display: ${({ $inSidebar }) => ($inSidebar ? 'none' : 'flex')};
  }
`;

export const Overlay = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  height: 52px;
  width: 100vw;

  @media (max-width: ${breakpoints.l}) {
    display: block;
  }
`;

export const StyledButton = styled(Button)`
  padding: ${spacing.space4} ${spacing.space16} ${spacing.space4} ${spacing.space12};
  height: fit-content;
  border-radius: 50px !important;
  cursor: pointer;

  p {
    margin: 0;
  }

  svg {
    margin-right: ${spacing.space8};
  }
`;

export const Header = styled(Text)`
  text-align: left;
  margin: 0 0 ${spacing.space16} ${spacing.space24};
`;
