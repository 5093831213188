import React from 'react';
import styled from 'styled-components';
import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing, themeLight } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import iconMapper from './IconMapper';

type Props = {
  parameters?: { title?: string; value?: string; icon?: string }[];
};

const ProductData: React.FC<Props> = ({ parameters }) => {
  if (!parameters || parameters.length === 0) {
    return null;
  }

  return (
    <DataList>
      {parameters.map((parameter) => (
        <DataEntry
          key={parameter?.title}
          title={parameter?.title}
          value={parameter?.value}
          icon={iconMapper(parameter?.icon)}
        />
      ))}
    </DataList>
  );
};

type EntryProps = {
  title?: string;
  value?: string;
  icon?: React.ReactElement;
};

const DataEntry = ({ title, value, icon }: EntryProps) => (
  <Entry>
    <IconContainer>{icon}</IconContainer>
    <Label variant={TextVariant.ArticleText}>{title}</Label>
    <Value variant={TextVariant.ArticleTextHeader}>{value}</Value>
  </Entry>
);

const DataList = styled.div`
  display: flex;
  flex-flow: row wrap;
  --content-plus-whitespace: calc(100vw - var(--aside));
  --max-width-with-whitespace: min(calc(936px + 2 * 96px), var(--content-plus-whitespace));
  --total-margins: calc((736px - var(--max-width-with-whitespace)) / 2);
  width: calc(736px - var(--total-margins));
  margin-left: max(var(--total-margins) / 2, -96px);
  margin-right: max(var(--total-margins) / 2, -96px);
  margin-bottom: ${spacing.space48};

  @media (max-width: ${breakpoints.s}) {
    flex-flow: column nowrap;
  }

  @media (max-width: ${breakpoints.l}) {
    --aside: 0;
  }

  @media (max-width: ${breakpoints.l}) {
    margin-bottom: ${spacing.space40};
  }

  @media (max-width: ${breakpoints.m}) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;

const Entry = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: ${spacing.space4};
  box-sizing: border-box;
  flex: 0 0 50%;

  @media (max-width: ${breakpoints.s}) {
    & + & {
      border-top: 1px solid ${({ theme }) => theme?.border?.subtle || themeLight.border.subtle};
      padding: ${spacing.space16} 0;
    }

    &:first-of-type {
      padding-bottom: ${spacing.space16};
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  @media (min-width: calc(${breakpoints.s} + 1px)) {
    &:first-of-type,
    &:nth-of-type(2) {
      padding-bottom: ${spacing.space16};
    }

    &:nth-of-type(2n + 1) {
      padding-right: ${spacing.space16};
    }

    &:nth-of-type(2n) {
      border-left: 1px solid ${({ theme }) => theme?.border?.subtle || themeLight.border.subtle};
      padding-left: ${spacing.space16};
    }

    /* Every element except the first two/the first row */
    &:nth-of-type(n + 3) {
      border-top: 1px solid ${({ theme }) => theme?.border?.subtle || themeLight.border.subtle};
      padding-top: ${spacing.space16};
      padding-bottom: ${spacing.space16};
    }

    &:last-of-type:nth-of-type(2n),
    &:nth-last-of-type(2):nth-of-type(2n + 1) {
      padding-bottom: 0;
    }

    &:last-of-type:nth-of-type(2n + 1) {
      border-right: 1px solid ${({ theme }) => theme?.border?.subtle || themeLight.border.subtle};
      padding-bottom: 0;
      box-sizing: content-box;
      flex-basis: calc(50% - ${spacing.space16});
    }

    &:nth-last-of-type(2):nth-of-type(2n) {
      border-bottom: 1px solid ${({ theme }) => theme?.border?.subtle || themeLight.border.subtle};
      margin-bottom: -1px;
    }
  }
`;

const Label = styled(Text)`
  flex-grow: 1;
  display: block;
  margin: 0;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const Value = styled(Text)`
  flex-shrink: 0;
  margin: 0;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const IconContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${spacing.space24};
  height: ${spacing.space24};
  color: ${nafColor.primary.park};

  svg path {
    fill: ${nafColor.primary.park};
  }
`;

export { ProductData };
