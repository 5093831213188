import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  background: white;
  overflow-y: scroll;
  top: 80px;
  position: sticky;
  min-width: 240px;
  width: 25vw;
  max-width: 400px;
  height: calc(100vh - 80px);

  #nav-button,
  #vehicle {
    display: flex;
  }

  @media (max-width: ${breakpoints.l}) {
    position: relative;
    overflow-y: hidden;
    max-width: unset;
    height: unset;
    top: 0;
    width: 100vw;
    height: 60px;
    transition: height 0.3s;
  }
`;

export const MenuButtons = styled.div``;

export const ProductCrumb = styled.button`
  display: flex;
  align-items: flex-start;
  font-variant-numeric: lining-nums;
  border: 0;
  text-align: left;
  background: transparent;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.border.subtle};
  padding: ${spacing.space8} ${spacing.space4} ${spacing.space8} ${spacing.space24};
  color: ${({ theme }) => theme.typography.defaultText};

  > p {
    margin: 0;
    margin-left: ${spacing.space8};
  }

  svg {
    margin-top: 4px;
    min-width: 24px;
  }

  &:hover {
    color: ${({ theme }) => theme.typography.textLink};
    background: ${({ theme }) => theme.componentColors.cta.disabled};
  }
`;

export const Button = styled.button<{ $inView: boolean }>`
  border: 0;
  font-variant-numeric: lining-nums;
  text-align: left;
  width: 100%;
  background: transparent;
  border-bottom: 1px solid ${({ theme }) => theme.border.subtle};
  padding: ${spacing.space8} ${spacing.space16} ${spacing.space8} ${spacing.space24};
  cursor: pointer;

  color: ${({ theme }) => theme.typography.defaultText};
  &:hover {
    color: ${({ theme }) => theme.typography.textLink};
    background: ${({ theme }) => theme.componentColors.cta.disabled};
  }
`;
