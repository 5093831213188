import React from 'react';
import styled from 'styled-components';
import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { Label } from '@naf/label';

type HeaderTextProps = {
  score?: {
    scoreValue?: number;
    maxValue?: number;
    isWinner?: boolean;
  };
  title?: string;
  ingress?: string;
};

const Header = ({ title, ingress, score }: HeaderTextProps) => {
  const scoreLabel = (scoreValue: number, maxValue: number) => `${scoreValue} poeng av ${maxValue}`;

  return (
    <HeaderContainer>
      {score && (
        <StyledLabel
          variant={score.isWinner ? 'signature' : 'moss'}
          text={score.isWinner ? 'Testvinner' : scoreLabel(score.scoreValue ?? 0, score.maxValue ?? 10)}
        />
      )}
      <MarginlessText variant={TextVariant.Display}>{title}</MarginlessText>
      <MarginlessText variant={TextVariant.Ingress}>{ingress}</MarginlessText>
    </HeaderContainer>
  );
};

const MarginlessText = styled(Text)`
  margin: 0;
  text-align: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: ${spacing.space16};
  margin: 0 auto;
  max-width: ${breakpoints.m};
`;

const StyledLabel = styled(Label)``;

export { Header };
