import React from 'react';
import { CreditCard } from '@styled-icons/material-outlined/CreditCard';
import { Luggage } from '@styled-icons/material-outlined/Luggage';
import { Bolt } from '@styled-icons/material/Bolt';
import { Autorenew } from '@styled-icons/material/Autorenew';
import { CarRepair } from '@styled-icons/material/CarRepair';
import AcUnit from './ProductDataIcons/ac_unit.svg';
import AccessTime from './ProductDataIcons/access_time.svg';
import BikeScooter from './ProductDataIcons/bike_scooter.svg';
import CalendarToday from './ProductDataIcons/calendar_today.svg';
import ChildSeatReverse from './ProductDataIcons/child_seat_reverse.svg';
import ChildSeat from './ProductDataIcons/child_seat.svg';
import DirectionsBike from './ProductDataIcons/directions_bike.svg';
import DriveEta from './ProductDataIcons/drive_eta.svg';
import ElectricBike from './ProductDataIcons/electric_bike.svg';
import ElectricMoped from './ProductDataIcons/electric_moped.svg';
import ElectricalServices from './ProductDataIcons/electrical_services.svg';
import FlashOn from './ProductDataIcons/flash_on.svg';
import LocalTaxi from './ProductDataIcons/local_taxi.svg';
import PedalBike from './ProductDataIcons/pedal_bike.svg';
import RvHookup from './ProductDataIcons/rv_hookup.svg';
import Toys from './ProductDataIcons/toys.svg';
import Nyttelast from '../../../Elbilguiden/VehicleDataIcons/nyttelast.svg';
import Rekkevidde from '../../../Elbilguiden/VehicleDataIcons/rekkevidde.svg';

const iconMapper = (iconName) => {
  const icons = {
    AccessTimeIcon: <AccessTime />,
    AcUnitIcon: <AcUnit />,
    BikeScooterIcon: <BikeScooter />,
    CalendarTodayIcon: <CalendarToday />,
    ChildSeat1Icon: <ChildSeatReverse />,
    ChildSeatIcon: <ChildSeat />,
    DirectionsBikeIcon: <DirectionsBike />,
    DriveEtaIcon: <DriveEta />,
    ElectricalServicesIcon: <ElectricalServices />,
    ElectricBikeIcon: <ElectricBike />,
    ElectricMopedIcon: <ElectricMoped />,
    FlashOnIcon: <FlashOn />,
    LocalTaxiIcon: <LocalTaxi />,
    PedalBike2Icon: <PedalBike />,
    RvHookupIcon: <RvHookup />,
    ToysIcon: <Toys />,
    WeightIcon: <Nyttelast />,
    RoadVariantIcon: <Rekkevidde />,
    CreditCardIcon: <CreditCard />,
    LuggageIcon: <Luggage />,
    BoltIcon: <Bolt />,
    AutorenewIcon: <Autorenew />,
    CarRepairMaterialIcon: <CarRepair />,
  };
  return icons[iconName] || null;
};

export default iconMapper;
