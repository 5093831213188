import React from 'react';
import styled from 'styled-components';
import { Grid, GridCol } from '@naf/grid';
import { animated, useSpring } from '@react-spring/web';
import { breakpoints } from '@naf/theme';

interface Props {
  itemsCount?: number;
}

const TestSearchSort = ({ itemsCount }: Props) => {
  const { amount } = useSpring({ amount: itemsCount });

  return (
    <Wrapper>
      <Grid>
        <GridCol s="12" m="12" l="4" xl="4">
          <SearchWrapper>{/* <TestSearch additionalFilters={additionalFilters} /> */}</SearchWrapper>
        </GridCol>
        <StyledGridCol s="12" m="12" l="8" xl="8">
          <DropdownArea>
            <div className="sortedResultNumber">
              <animated.strong>{amount.to((val) => Math.floor(val as number))}</animated.strong>
              <strong> treff</strong>
            </div>
          </DropdownArea>
        </StyledGridCol>
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const SearchWrapper = styled.div`
  @media (min-width: ${breakpoints.l}) {
    width: calc(75% + 12px);
  }
`;

const StyledGridCol = styled(GridCol)`
  padding-right: 0;
`;

const DropdownArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgrey;

  .sortedResultNumber {
    white-space: nowrap;
  }

  strong {
    font-weight: bold;
  }

  button {
    margin-right: -16px;
  }
`;

export default TestSearchSort;
