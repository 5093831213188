import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Text, TextVariant } from '@naf/text';
import { ExpandLess } from '@styled-icons/material-outlined/ExpandLess';
import { ExpandMore } from '@styled-icons/material-outlined/ExpandMore';
import useSelector from '../../../../redux/typedHooks';
import { RelatedModelSkeleton } from '../../../Elbilguiden/RelatedModelSkeleton';
import { RelatedModelItem } from './RelatedModelItem';
import { actions as guides } from '../../../../redux/modules/consumerGuides';
import { ConsumerTestedProduct } from '../../../../../../types/consumerTestType';
import * as S from './Styles';

type Props = {
  inSidebar?: boolean;
  guideSlug: string;
  productSlug: string;
};

export const RelatedModels: React.FC<Props> = ({ guideSlug, productSlug, inSidebar = false }) => {
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(false);
  const { selectedGuide, isUpdatingGuide, isUpdatingProducts, relatedProducts } = useSelector(
    (state) => state.consumerGuides,
  );

  // Fetch the guide when `guideSlug` changes or when it's missing in the state.
  useEffect(() => {
    if (guideSlug && (!selectedGuide || selectedGuide.slug !== guideSlug)) {
      dispatch(guides.getOneGuide.request(guideSlug, { token: undefined }));
    }
  }, [guideSlug, selectedGuide, dispatch]);

  // Fetch related products when the guide is successfully fetched.
  useEffect(() => {
    if (selectedGuide && (!relatedProducts || relatedProducts.items.length === 0)) {
      dispatch(guides.getRelatedProducts.request(selectedGuide.id));
    }
  }, [selectedGuide, relatedProducts, dispatch]);

  if (isUpdatingGuide || isUpdatingProducts) {
    return (
      <S.Container $inSidebar={inSidebar} $showAll={inSidebar ? true : showAll}>
        <S.Header variant={TextVariant.Header3}>Lignende modeller</S.Header>
        {Array.from(Array(8).keys()).map((key) => (
          <RelatedModelSkeleton key={key} />
        ))}
      </S.Container>
    );
  }

  return (
    <>
      <S.Container $inSidebar={inSidebar} $showAll={inSidebar ? true : showAll}>
        <S.Header variant={TextVariant.Header3}>Lignende modeller</S.Header>
        {relatedProducts?.items?.map((item: ConsumerTestedProduct) => (
          <RelatedModelItem
            key={item.id}
            variant="list-item"
            relatedProduct={item}
            isCurrent={productSlug === item.slug}
            inSidebar={inSidebar}
            guideSlug={guideSlug}
          />
        ))}
        {!showAll && <S.Overlay />}
      </S.Container>
      <S.ButtonContainer $inSidebar={inSidebar}>
        <S.StyledButton type="button" variant="secondary" onClick={() => setShowAll(!showAll)}>
          {showAll ? (
            <>
              <ExpandLess height={16} width={16} />
              <Text>Vis færre</Text>
            </>
          ) : (
            <>
              <ExpandMore height={16} width={16} />
              <Text>Vis flere</Text>
            </>
          )}
        </S.StyledButton>
      </S.ButtonContainer>
    </>
  );
};
