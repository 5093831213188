import React, { ReactNode } from 'react';
import { breakpoints, spacing } from '@naf/theme';
import styled from 'styled-components';

type Props = {
  children?: ReactNode;
};

export const GuideContentWrapper = ({ children }: Props) => <GuideContentContainer>{children}</GuideContentContainer>;

const GuideContentContainer = styled.div`
  margin-bottom: ${spacing.space64};

  & img + img {
    margin-top: 0;
  }

  & ul,
  & ol {
    margin-top: ${spacing.space16};
    margin-bottom: ${spacing.space24};
  }

  @media (max-width: ${breakpoints.l}) {
    margin-bottom: ${spacing.space40};
  }
`;
