import React from 'react';
import { PortableTextBlock } from '@portabletext/react';
import { InfoBox } from '@naf/info-box';
import BlockContent from '../../../components/block-content/BlockContent';

interface InfoBoxProps {
  displayOption?: 'standard' | 'green';
  title?: string;
  text: PortableTextBlock[];
}

const ProductTestInfoBox = ({ title, text, displayOption }: InfoBoxProps) => (
  <InfoBox displayColor={displayOption} title={title} content={<BlockContent value={text} />} />
);

export { ProductTestInfoBox };
