import React from 'react';
import { SubdirectoryArrowRight } from '@styled-icons/material/SubdirectoryArrowRight';
import { Text, TextVariant } from '@naf/text';
import { BackToTopButton } from '../../../Elbilguiden/BackToTopButton';
import CustomBreadcrumb from '../CustomBreadCrumb';
import { RelatedModels } from '../RelatedModels/RelatedModels';
import { ScrollSection, SectionKey } from '../../TestedProductPage/TestedProductContent/TestedProductContent';
import * as S from './Styles';

type Props = {
  sections?: ScrollSection[];
  sectionInView: SectionKey;
  navigateToSection?: (key: SectionKey) => void;
  modelName: string;
  slug?: string;
  guideSlug?: string;
};

export const SectionsNavigation: React.FC<Props> = ({
  sections,
  sectionInView,
  navigateToSection,
  slug,
  guideSlug,
  modelName,
}) => (
  <>
    <S.Nav>
      <S.MenuButtons>
        <CustomBreadcrumb basePath="/forbrukertester" />
        {sections
          ?.filter(({ label }) => label)
          .map(({ key, label }) =>
            key === 'model' ? (
              <S.ProductCrumb
                id="vehicle"
                type="button"
                key={key}
                onClick={() => {
                  navigateToSection(key);
                }}
              >
                <SubdirectoryArrowRight width={24} height={24} />
                <Text tag="p" variant={TextVariant.Header3}>
                  {modelName}
                </Text>
              </S.ProductCrumb>
            ) : (
              <S.Button
                id="nav-button"
                $inView={sectionInView === key}
                type="button"
                key={key}
                onClick={() => {
                  navigateToSection(key);
                }}
              >
                {label}
              </S.Button>
            ),
          )}
      </S.MenuButtons>
      <RelatedModels inSidebar guideSlug={guideSlug} productSlug={slug} />
    </S.Nav>
    <BackToTopButton show onClick={() => navigateToSection(sections[0].key)} />
  </>
);
