import React from 'react';
import { Text, TextVariant } from '@naf/text';
import CarSubListPlaceholder from '../../bilguide/carlist/CarSubListPlaceholder';
import { LazyImage } from '../../lazy-image/LazyImage';
import { ConsumerTestedProduct } from '../../../../../types/consumerTestType';
import * as S from './Styles';

interface Props {
  testedProducts: ConsumerTestedProduct | null;
  guideSlug: string;
  isUpdatingProducts?: boolean;
}

const TestListItem = ({ testedProducts, isUpdatingProducts, guideSlug }: Props) => {
  if (isUpdatingProducts) {
    return <CarSubListPlaceholder key="loading-placeholder" />;
  }

  const modifyCloudinaryImageUrlToThumbnail = (path: string) => path.replace('/upload/v', '/upload/c_scale,w_330/v');
  const scoreLabel = (scoreValue: number, maxValue: number) => `${scoreValue} poeng av ${maxValue}`;

  const { title, slug, score, price, parameterLabels } = testedProducts;

  return (
    <S.Wrapper>
      <S.ListElement to={`/forbrukertester/${guideSlug}/${slug}`}>
        <S.ElementImage>
          <LazyImage
            actualSrc={
              testedProducts.image?.srcSet?.jpgLarge
                ? modifyCloudinaryImageUrlToThumbnail(testedProducts?.image?.srcSet?.jpgLarge)
                : ''
            }
            displayName={title}
            altText={testedProducts.image?.alt || testedProducts.image?.caption || ''}
          />
        </S.ElementImage>
        {score && (
          <S.StyledLabel
            variant={score.isWinner ? 'signature' : 'moss'}
            text={score.isWinner ? 'Testvinner' : scoreLabel(score.scoreValue ?? 0, score.maxValue ?? 10)}
          />
        )}
        <S.ElementInfo>
          <Text tag="h3" variant={TextVariant.Header3}>
            {title}
          </Text>
          <S.LabelList>
            {parameterLabels.map((parameterLabel: string) => (
              <S.LabelListElement key={`${testedProducts.id}-label-${parameterLabel}`}>
                {parameterLabel}
              </S.LabelListElement>
            ))}
          </S.LabelList>
          <S.PriceInfoWrapper>
            <S.ElementPrice>fra {price?.toLocaleString() || '-'},-</S.ElementPrice>
          </S.PriceInfoWrapper>
        </S.ElementInfo>
      </S.ListElement>
    </S.Wrapper>
  );
};

export default TestListItem;
